import { Nav } from "../components/nav";
import { NewsCards } from "../components/cards";
import { Footer } from "../components/footer";
import { useState } from "react";
import idat from '../assets/blog-image.jpg'

const Hero = { img: "/hero-1.jpeg" };

// News Data
const News = [
  {
    id: 1,
    title: "Dimension11 Games resurfaces with New Publishing update",
    description:
      "Dimension11 Games is an independent game developer based in Nigeria. Their latest project, Legends of Orisha: Blood and Water, is a Yoruba story-based action-adventure role-playing game, which has garnered significant interest since its announcement several years ago and culminated in the game taking home the 2022 GIAA One to Watch in December.",
    image:idat,
    link: "https://gamesindustryafrica.com/2023/03/13/dimension-11-games-resurfaces-with-new-publishing-update/",
    target:""
  },
  {
    id: 2,
    title: "Dimension 11 open up about Legends of Orisha",
    description:
      "Games Industry Africa had a chat with a representative of Dimension 11 studios, to discuss their upcoming game – Legends of Orisha: Blood and Water. This game is a Yoruba Story-Based Action-Adventure Role Playing Game that the team have been working on for over a year now and is set to launch in 2022.",
    image:
      "/lorishaNew.jpg",
    link: "https://gamesindustryafrica.com/2021/12/15/dimension-11-open-up-about-legends-of-orisha/",
    target:""
  },
  // {
  //   id: 3,
  //   title: "Reef Grant Fund Allocates Over $200K to Wave 1 of Grantees!",
  //   description:
  //   "Since the Reef mainnet launch in July 2021, the amount of support we have received from the Reef community has been astounding. You have been incredibly patient as we deploy more tools for developers, enhance existing solutions, and continue to grow the ecosystem with more relationships. Whether you’re a Validator, Nominator, developer or community member, we all have a role to play in making Reef into a successful ecosystem that showcases the best that DeFi, NFTs and gaming have to offer the world.",
  //   image: "https://miro.medium.com/max/1400/1*uvOv9MUN_F__TTVJZO456w.png",
  //   link: "https://medium.com/reef-finance/reef-grant-fund-allocates-over-200k-to-wave-1-of-grantees-35e7a86ea6d2",
  // target:""
  // },
  {
    id: 3,
    title: "Legends of Orisha: Blood and Water",
    description:
      "Legends of Orisha: Blood and Water est un jeu en cours de développement par Dimension 11 Games, studio indépendant nigérian dirigé par Ekpenisi-Igumbor Ewere. Le jeu est réalisé sous Unreal Engine et sa sortie est prévue pour 2022. Il se présente comme un RPG mêlant action et aventure. L’histoire est d’ailleurs centrée sur les Yorubas, l’une des plus grandes ethnies d’Afrique.",
    image: "https://www.afrogameuses.com/wp-content/uploads/2022/01/9_jeux_video-768x432.png",
    link: "https://www.afrogameuses.com/representativite/9-jeux-video-crees-par-des-personnes-noires-ou-afrodescendantes/#loo",
    target:"blank"
  },
];

const NewsView = () => {
  const [awaiting, setAwait] = useState(false);
  return (
    <section>
      <span className="fixed z-0 w-screen h-screen">
        <img
          className="w-full"
          src={Hero.img}
          alt="Dimension hero background"
        />
      </span>
      {/* <div className="z-0 bg-gradient-to-b from-black to-transparent mix-blend-overlay absolute w-full h-full"></div> */}
      <Nav />
      <div className="relative z-10 bg-gradient-to-b from-black/[0.7] to-black/[0.3] py-16 flex flex-col items-center">
        <div className="max-w-2xl my-20">
          <div className="flex flex-col items-center space-y-8 my-auto">
            <h1 className="text-5xl text-white text-center font-bold">News</h1>
            {/* <form className="flex flex-col lg:flex-row lg:justify-center lg:w-full">
              <input
                className="w-full p-4 appearence-none rounded text-black text-center focus:outline-none focus:shadow-outline"
                type="text"
              />
            </form> */}
            {/* <div className="flex flex-col space-y-4 w-full lg:flex-row lg:space-x-4 lg:space-y-0">
              <button className="bg-yellow-400 rounded-sm py-2 px-4 hover:bg-white">
                <small className="text-sm lg:text-base font-normal">All</small>
              </button>
              <button className="bg-transparent text-white border-2 border-white rounded-sm py-2 px-4 hover:bg-yellow-400 hover:text-black hover:border-yellow-400">
                <small className="text-sm lg:text-base font-normal">
                  Announcement
                </small>
              </button>
              <button className="bg-transparent text-white border-2 border-white rounded-sm py-2 px-4 hover:bg-yellow-400 hover:text-black hover:border-yellow-400">
                <small className="text-sm lg:text-base font-normal">
                  Behind the Scenes
                </small>
              </button>
              <button className="bg-transparent text-white border-2 border-white rounded-sm py-2 px-4 hover:bg-yellow-400 hover:text-black hover:border-yellow-400">
                <small className="text-sm lg:text-base font-normal">
                  Publications
                </small>
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="relative z-10 bg-white p-12">
        {awaiting ? (
          <ComingSoon />
        ) : (
          <div className="flex flex-col items-start space-y-12">
            <div className="max-w-6xl hidden lg:flex">
              {News.map((news) => {
                return (
                  <>
                    {news.id === 1 && (
                      <NewsCards
                        key={news.id}
                        row={true}
                        img={news.image}
                        link={news.link}
                        heading={news.title}
                        content={`${news.description.slice(0, 78)} ...`}
                        
                      />
                    )}
                  </>
                );
              })}
            </div>
            <div className="max-w-6xl flex lg:hidden">
              {News.map((news) => {
                return (
                  <>
                    {news.id === 1 && (
                      <NewsCards
                        key={news.id}
                        row={false}
                        img={news.image}
                        link={news.link}
                        heading={news.title}
                        content={`${news.description.slice(0, 78)} ...`}
                      />
                    )}
                  </>
                );
              })}
            </div>
            <div className="container flex flex-col space-y-4 lg:flex-row lg:justify-center lg:space-x-12 lg:space-y-0">
              {News.map((news) => {
                return (
                  <>
                    {news.id >= 2 && (
                      <NewsCards
                        key={news.id}
                        row={false}
                        img={news.image}
                        link={news.link}
                        heading={news.title}
                        content={`${news.description.slice(0, 300)} ...`}
                        target={news.target}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="relative z-10 py-12 bg-white flex flex-col items-center">
        <div className="my-4 text-center">
          <small className="text-xl font-medium text-black">
            {`Showing ${2} to ${8} of ${12}`}
          </small>{" "}
        </div>
        <div className="flex w-max space-x-8">
          <button className="bg-yellow-400 text-black py-2 px-12 rounded-sm border-2 border-yellow-400 hover:bg-transparent hover:text-yellow-400 hover:border-2 hover:border-yellow-400">
            Show more
          </button>
        </div>
      </div>
      <div className="relative z-10">
        <Footer />
      </div>
    </section>
  );
};

const ComingSoon = () => {
  return (
    <>
      <div className="flex w-full flex-row justify-center">
        <div className="bg-yellow-400 p-8 w-6/12 flex flex-row justify-center">
          <span className="text-center">
            <h1 className="animate-pulse text-bold text-4xl">Coming soon</h1>
          </span>
        </div>
      </div>
    </>
  );
};
export default NewsView;
